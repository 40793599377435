import React from "react";
import styled from "@emotion/styled";
import { Media } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Icon } from "@ryerson/frontend.assets";
import { css } from "@emotion/react";
import rgba from "polished/lib/color/rgba";

export type WhatOurPeopleSayProps = {
	title: string;
	navLabel: string;
	quote: string;
	name: string;
	jobTitle: string;
	imageUrl: string;
	background?: "primary" | "secondary" | "tertiary";
};

export interface WhatOurPeopleSayContentProps {
	content: WhatOurPeopleSayProps;
}

const AddMargin = css`
	margin-top: 50px;
`;
const MainText = styled(FlexItem)`
	position: relative;
	z-index: 1;
	width: 600px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.xl}) {
				width: 800px;
			}
		`;
	}}
`;
const LeftContent = styled(Flex)`
	width: 100%;
	h2 {
		margin-top: 0px;
	}
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				width: 50%;
			}
			@media (min-width: 560px) and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 30px;
			}
		`;
	}}
`;
const SecondaryText = styled.div`
	${(props: any) => {
		const { theme } = props;
		return `
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 27px;
			}
		`;
	}}
`;
const StartQuote = styled(Icon)`
	margin-right: 12px;
	${(props: any) => {
		const { theme } = props;
		return `
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-right: 5px;
				left: -10px;	
			}
		`;
	}}
`;
const EndQuote = styled(Icon)`
	transform: rotate(180deg) translateY(8px);
`;

const DesktopBeginningQuote = styled.span`
	position: absolute;
	left: -25px;
	top: 0;
	font-size: 30px;
	@media (max-width: 1100px) {
		left: -20px;
	}
	${(props: any) => {
		const { theme } = props;
		return css`
			color: ${theme.colors.primary.lightGray};
		`;
	}}
`;
const DesktopEndingQuote = styled.span`
	font-size: 30px;
	margin-left: 12px;
	${(props: any) => {
		const { theme } = props;
		return css`
			color: ${theme.colors.primary.lightGray};
		`;
	}}
`;

const HeaderContent = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${rgba(theme.colors.primary.secondaryBrand, 0.1)};
		`;
	}}
`;

const PhotoContainer = styled(FlexItem)`
	text-align: right;
	img {
		max-height: 100%;
	}
	${(props: any) => {
		const { theme } = props;
		return css`
			width: 100%;
			@media only screen and (max-width: ${theme.breakpoints.sm}) {
				min-width: 300px;
				min-height: 200px;
				margin-bottom: 30px;
				text-align: center;
				img {
					max-width: 65%;
				}
			}
			@media only screen and (min-width: ${theme.breakpoints.sm}) and (max-width: ${theme
					.breakpoints.lg}) {
				height: 500px;
				text-align: center;
			}
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				height: 500px;
			}
			@media only screen (min-width: 1100px) {
				min-width: 560px;
			}
		`;
	}}
`;

const WhatOurPeopleSay: React.FC<WhatOurPeopleSayContentProps> = ({ content }) => {
	const { theme } = useTheme();

	return (
		<>
			<Media lessThan="lg">
				<ContentSection hPadding="0px" vPadding="40px">
					<Flex direction="column">
						<Flex
							direction="column"
							style={css`
								position: relative;
							`}
						>
							<FlexItem>
								<PhotoContainer theme={theme}>
									<img
										src={content.imageUrl}
										css={css`
											max-height: 100%;
										`}
									/>
								</PhotoContainer>
							</FlexItem>
						</Flex>
						<LeftContent theme={theme}>
							<FlexItem>
								<StartQuote
									theme={theme}
									icon="quote"
									size="xs"
									color={theme.colors.primary.lightGray}
								/>
							</FlexItem>
							<FlexItem>
								<Typography
									variant="div"
									color={theme.colors.primary.gray}
									size="xl"
								>
									{content.quote}
									<EndQuote
										icon="quote"
										size="xs"
										color={theme.colors.primary.lightGray}
									/>
								</Typography>
								<SecondaryText theme={theme}>
									<Typography variant="div" size="md" weight="bold">
										{content.name}
									</Typography>
									<Typography
										variant="div"
										size="md"
										color={theme.colors.primary.gray}
									>
										{content.jobTitle}
									</Typography>
								</SecondaryText>
							</FlexItem>
						</LeftContent>
					</Flex>
				</ContentSection>
			</Media>
			<Media greaterThanOrEqual="lg">
				<ContentSection
					vPadding="80px"
					type={content.background ? content.background : "secondary"}
				>
					<HeaderContent theme={theme}>
						<Typography
							variant="h1"
							css={css`
								margin-bottom: 40px;
							`}
						>
							{content.title}
						</Typography>
					</HeaderContent>
					<Flex css={AddMargin}>
						<LeftContent theme={theme} direction="column">
							<MainText theme={theme}>
								<Flex>
									<FlexItem>
										<Typography
											css={css`
												letter-spacing: -2px;
												margin: 0;
												position: relative;
											`}
											variant="h2"
											color={theme.colors.primary.gray}
										>
											<DesktopBeginningQuote theme={theme}>
												“
											</DesktopBeginningQuote>
										</Typography>
									</FlexItem>
									<FlexItem>
										<Typography
											variant="h2"
											color={theme.colors.primary.gray}
											css={{ marginBottom: "40px" }}
										>
											{content.quote}
											<DesktopEndingQuote theme={theme}>”</DesktopEndingQuote>
										</Typography>
										<SecondaryText theme={theme}>
											<Typography variant="div" size="sm" weight="bold">
												{content.name}
											</Typography>
											<Typography
												variant="div"
												size="sm"
												color={theme.colors.primary.gray}
											>
												{content.jobTitle}
											</Typography>
										</SecondaryText>
									</FlexItem>
								</Flex>
							</MainText>
						</LeftContent>
						<Flex
							direction="column"
							style={css`
								width: 100%;
							`}
						>
							<FlexItem>
								<PhotoContainer theme={theme}>
									<img
										src={content.imageUrl}
										css={css`
											max-height: 100%;
										`}
									/>
								</PhotoContainer>
							</FlexItem>
						</Flex>
					</Flex>
				</ContentSection>
			</Media>
		</>
	);
};

export default WhatOurPeopleSay;
